export class Fetcher {
    constructor() {
        this.apiUrl = process.env.REACT_APP_API_URL
    }

    async fetchCategories() {
        try {
            const response = await fetch(this.apiUrl + '/catalog/categories')
            const data = await response.json()
            return data
        } catch (err) {
            console.log(err)
            return []
        }
    }

    async fetchSubcategories(params) {
        try {
            const response = await fetch(this.apiUrl + '/catalog/subcategories?' + params)
            const data = await response.json()
            return data
        } catch (err) {
            console.log(err)
            return []
        }
    }

    async fetchProduct(params) {
        try {
            const response = await fetch(this.apiUrl + '/catalog/product?' + params);
            const data = await response.json()
            return data
        } catch (err) {
            console.log(err)
            return []
        }
    }

    async postForm(endpoint, data) {
        try {
            const res = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!res.ok) {
                throw new Error('Ошибка при отправке данных')
            }

            const resData = await res.json()
            console.log(resData)
        } catch (error) {
            console.warn(error)
        }
    }

    // async postOrder(data) {
    //     try {
    //         const res = await fetch(process.env.REACT_APP_API_URL + '/checkout', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(data)
    //         });

    //         if (!res.ok) {
    //             throw new Error('Ошибка при отправке данных')
    //         }

    //         const resData = await res.json()
    //         console.log(resData)
    //     } catch (e) {
    //         console.warn(e)
    //     }
    // }
}