import style from './MailComponent.module.css'

export const MailComponent = () => {
    return (
        <a className={style.mail} href="mailto:info@conturspb.ru">
            <div className={style.img}>
                <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.36859 18.8232H17.1686C18.1021 18.8232 18.9975 18.4523 19.6576 17.7922C20.3177 17.132 20.6886 16.2368 20.6886 15.3032V6.50322C20.6886 5.56966 20.3177 4.67428 19.6576 4.01414C18.9975 3.35402 18.1021 2.98322 17.1686 2.98322H6.60859C5.67503 2.98322 4.7797 3.35402 4.11957 4.01414C3.45944 4.67428 3.08859 5.56966 3.08859 6.50322V10.9032M1.32858 16.1832H10.1286M1.32858 13.5432H4.84858M19.9839 4.76953C15.0471 14.1327 8.71118 14.1239 3.77438 4.76953" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <p>info@conturspb.ru</p>
        </a>
    )
}