import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import { useEffect, useState } from 'react'
import { apiUrl } from '../../../axios'
import { MailComponent } from '../../UI/MailComponent/MailComponent'
import { PhoneComponent } from '../../UI/PhoneComponent/PhoneComponent'
import { Container } from '../../UI/Container/Container'
import { Modal } from '../../UI/Modal/Modal'

export const Footer = () => {
    const [cats, setCats] = useState([])
    const [loading, setLoading] = useState(true)
    const [isActive, setInactive] = useState(false)

    useEffect(() => {
        apiUrl
            .get('/catalog/categories')
            .then((res) => {
                setCats(res.data.categories)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <footer className={styles.footer}>
                <Modal type='feedback' isActive={isActive} setInactive={setInactive} />
                <Container>
                    <div className={styles.footer_upper}>
                        <div className={styles.footer_logo}>
                            <img src="/logo_footer.png" alt="" />
                            <span>ООО "Контур"</span>
                        </div>
                    </div>
                    <div className={styles.footer_middle}>
                        <div className={styles.footer_ml}>
                            <div className={styles.footer_links}>
                                <Link to='/about'>О компании</Link>
                                <Link to='/payment'>Оплата</Link>
                                <Link to='/shipping'>Доставка</Link>
                                <Link to='/contacts'>Контакты</Link>
                            </div>
                            <div className={styles.footer_mr}>
                                <p className={styles.footer_catlink}>
                                    <Link to='/catalogue'>Каталог</Link>
                                </p>
                                <ul className={styles.footer_cats}>
                                    {loading ? (<>Загрузка...</>) : cats.map((cat, index) => {
                                        return (
                                            <li key={index} className={styles.footer_cat} id={index}>
                                                <Link to={`/catalogue/${cat.id}`}>{cat.title}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className={styles.footer_contacts}>
                            <p>Контакты</p>
                            <div className={styles.footer_mp}>
                                <MailComponent />
                                <PhoneComponent />
                            </div>
                            <button onClick={() => setInactive(prev => !prev)}>Связаться с нами</button>
                            <p>г. Санкт-Петербург, ул. Бурцева, д.23 лит. А, офис 9</p>
                        </div>
                    </div>
                    <div className={styles.footer_lower}>
                        2024 © ООО “Контур”. Все права защищены.
                    </div>
                </Container>
            </footer>
        </>
    )
}