

import { Form } from '../Form/Form'
import styles from './Modal.module.css'

export const Modal = ({ type, isActive, setInactive, addData }) => {
    return (
        <div className={isActive ? styles.form : styles.inactive}>
            <div className={styles.bg} onClick={() => setInactive(prev => !prev)}></div>
            {type === 'zakaz' ? (
                <div className={styles.form_content}>
                    <div className={styles.cross} onClick={() => setInactive(prev => !prev)}></div>
                    <h3>Оформление заказа</h3>
                    <p>Оставьте ваши контактные данные, мы отправим инофрмацию по заказу нашим менеджерам, и они свяжутся с вами для уточнения деталей</p>
                    <Form clname={styles.form_feedback} URI='/checkout' text="Заказ с сайта" funcClose={setInactive} additionalData={addData} />
                </div>
            ) : (<></>)}
            {type === 'feedback' ? (<>
                <div className={styles.form_content}>
                    <div className={styles.cross} onClick={() => setInactive(prev => !prev)}></div>
                    <h3>Оставьте ваши контакты</h3>
                    <p>Мы передадим ваши контакты менеджерам, и они с вамя свяжутся для решения вашего вопроса.</p>
                    <Form clname={styles.form_feedback} URI='/feedback' text="ОС формы фидбека" funcClose={setInactive} />
                </div>
            </>) : (<></>)}
        </div>
    )
}