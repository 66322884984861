import { DataContainer } from "../../components/Containers/DataContainer"
import { Feedback } from "../../components/Parts/Feedback/Feedback"
import { Spacer } from "../../classes/UI/Spacer"
import { Container } from "../../components/UI/Container/Container"
import { FS, AC, FH } from "../../components/UI/HomeComponents/HomeComponents"

export const Home = () => {
    return (
        <>
            <FS />
            <Container>
                <DataContainer URI="/catalog/categories" isMain />
            </Container>
            <Spacer h={66} />
            <Feedback />
            <AC />
            <FH />
        </>
    )
}