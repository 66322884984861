import { Link } from "react-router-dom"

import styles from "./HeaderLogo.module.css"

export const HeaderLogo = () => {
    return (
        <Link to="/">
            <div className={styles.logo}>
                <img src="/logo.jpg" alt="" />
                <p>ООО “КОНТУР”</p>
            </div>
        </Link>
    )
}