import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Container } from "../../UI/Container/Container"
import { HeaderLogo } from "../../UI/HeaderLogo/HeaderLogo"
import { MailComponent } from "../../UI/MailComponent/MailComponent"
import { PhoneComponent } from "../../UI/PhoneComponent/PhoneComponent"
import { LikeSvg } from "../../SVG/LikeSvg"
import { CartSvg } from "../../SVG/CartSvg"
import { UserSvg } from "../../SVG/UserSvg"

import styles from "./HeaderStill.module.css"
import { MenuBurger } from "../../SVG/MenuBurger"

export const HeaderStill = () => {
    const cart = useSelector(state => state.cart)
    const liked = useSelector(state => state.liked)
    return (
        <div className={styles.header}>
            <Container>
                <div className={styles.container}>
                    <MenuBurger clname={styles.burger} />
                    <div className={styles.options_left}>
                        <HeaderLogo />
                        <Link to="/catalogue">Каталог</Link>
                    </div>
                    <div className={styles.options_right}>
                        <div className={styles.contacts}>
                            <MailComponent />
                            <PhoneComponent />
                        </div>
                        <Link to="/liked"><div className={styles.like}><LikeSvg /><p className={styles.amount}>{liked.length}</p></div></Link>
                        <Link to="/cart"><div className={styles.cart}><CartSvg /><p className={styles.amount}>{cart.length}</p></div></Link>
                        <Link to="profile"><div className={styles.user}><UserSvg /></div></Link>
                    </div>
                </div>
            </Container>
        </div>
    )
}