import { HeaderLogo } from "../../UI/HeaderLogo/HeaderLogo"
import { Container } from "../../UI/Container/Container"
import { PhoneComponent } from "../../UI/PhoneComponent/PhoneComponent"
import { MailComponent } from "../../UI/MailComponent/MailComponent"

import styles from "./HeaderUpper.module.css"

export const HeaderUpper = () => {
    return (
        <>
            <Container>
                <div className={styles.container}>
                    <HeaderLogo />
                    <div className={styles.workingHours}>Пн – Пт: с 9:00 до 17:00</div>
                    <div className={styles.contacts}>
                        <MailComponent />
                        <PhoneComponent />
                    </div>
                </div>
            </Container>
        </>
    )
}