import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { Container } from "../../UI/Container/Container"
import { LikeSvg } from "../../SVG/LikeSvg"
import { CartSvg } from "../../SVG/CartSvg"
import { UserSvg } from "../../SVG/UserSvg"

import styles from "./HeaderLower.module.css"

export const HeaderLower = () => {
    const cart = useSelector(state => state.cart)
    const liked = useSelector(state => state.liked)
    return (
        <Container>
            <div className={styles.container}>
                {/* <HeaderLogo /> */}
                <div className={styles.options}>
                    <Link to="catalogue"><div className={styles.option}>Каталог</div></Link>
                    <Link to="about"><div className={styles.option}>О компании</div></Link>
                    <Link to="payments"><div className={styles.option}>Оплата</div></Link>
                    <Link to="shipping"><div className={styles.option}>Доставка</div></Link>
                    <Link to="contacts"><div className={styles.option}>Контакты</div></Link>
                </div>
                <div className={styles.usersettings}>
                    <Link to="/liked"><div className={styles.like}><LikeSvg /><p className={styles.amount}>{liked.length}</p></div></Link>
                    <Link to="/cart"><div className={styles.cart}><CartSvg /><p className={styles.amount}>{cart.length}</p></div></Link>
                    <Link to="profile"><div className={styles.user}><UserSvg /></div></Link>
                </div>
            </div>
        </Container>
    )
}