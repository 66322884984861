import { createSlice } from "@reduxjs/toolkit";
import ArrayLocalStorageState from "../../classes/utils/ArrayLocalStorageState";

const arrayState = new ArrayLocalStorageState('cart')
arrayState.createArrayState()

const initialState = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : arrayState.getArrayState();

const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        addToCart(state, action) {
            const { id } = action.payload;
            const existingPos = state.find(pos => pos.id === id)
            if (existingPos) {
                existingPos.count++
                localStorage.setItem('cart', JSON.stringify(state))
            } else {
                state.push(action.payload)
                localStorage.setItem('cart', JSON.stringify(state))
            }
        },
        decrementCart(state, action) {
            const { id } = action.payload;
            const existingPos = state.find(pos => pos.id === id);
            if (existingPos) {
                if (existingPos.count === 1) {
                    state.splice(state.indexOf(existingPos), 1)
                    localStorage.setItem('cart', JSON.stringify(state))
                }
                existingPos.count--
                localStorage.setItem('cart', JSON.stringify(state))
            }
        }
    }
})

export const { addToCart, decrementCart } = cartSlice.actions
export default cartSlice.reducer