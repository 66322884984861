import { useParams } from "react-router-dom"
import { apiUrl } from "../../axios"
import { Product } from "../../components/Parts/Product/Product"
import { useEffect, useState } from "react"
import { Feedback } from '../../components/Parts/Feedback/Feedback'
import { Container } from "../../components/UI/Container/Container"

import styles from './FullProduct.module.css'

export const FullProduct = () => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState()
    useEffect(() => {
        apiUrl
            .get('/catalog/product', { params })
            .then((res) => {
                setProduct(res.data.product)
                setLoading(false)
                console.log(res.data)
            })
            .catch((err) => { console.log(err) })
    }, [])
    console.log(params)
    return (
        <>
            <Container>
                <div className={styles.space128}></div>
                {loading ? (
                    <>
                    </>
                ) : (
                    <Product
                        isFullProduct
                        title={product.title}
                        image_urls={product.images_url}
                        parameters={product.parameters}
                        description={product.description}
                    />
                )}
            </Container>
            <Feedback />
        </>
    )
}