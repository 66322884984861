import { Outlet } from "react-router-dom";
import { Container } from "../../components/UI/Container/Container"

import styles from "./Categories.module.css"
import { Feedback } from "../../components/Parts/Feedback/Feedback";

export const Categories = () => {
    return (
        <>
            <Container>
                <div className={styles.container}>
                    <Outlet />
                </div>
            </Container>
            <Feedback />
        </>
    )
}
