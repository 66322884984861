export const ProductionSvg = () => {
    return (
        <>
            <svg viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M62.458 23.4921C61.5335 23.4921 60.7841 24.2448 60.7841 25.1731V29.9921C60.7841 30.9203 61.5335 31.673 62.458 31.673C63.3824 31.673 64.1318 30.9203 64.1318 29.9921V25.1731C64.132 24.2447 63.3826 23.4921 62.458 23.4921Z" fill="#FCFBFB" />
                <path d="M64.1311 35.8403C64.1311 35.3983 63.9536 34.9645 63.6423 34.6519C63.3293 34.3393 62.899 34.1594 62.4589 34.1594C62.017 34.1594 61.5867 34.3391 61.2738 34.6519C60.9625 34.9645 60.785 35.3983 60.785 35.8403C60.785 35.8482 60.7861 35.8562 60.7862 35.864C60.7859 35.8775 60.7843 35.8905 60.7843 35.9038V36.0718C60.7843 37.0001 61.5336 37.7528 62.4581 37.7528C63.3826 37.7528 64.132 37.0001 64.132 36.0718V35.9038C64.132 35.8902 64.1303 35.8769 64.13 35.8633C64.1298 35.8554 64.1311 35.8479 64.1311 35.8403Z" fill="#FCFBFB" />
                <path d="M72.5015 47.9798V20.701C72.5015 18.3701 70.6133 16.4738 68.2922 16.4738H56.6237C54.3027 16.4738 52.4144 18.37 52.4144 20.701V47.5725H46.7021V14.2886H51.3054C52.2299 14.2886 52.9793 13.5359 52.9793 12.6077V8.82533H56.2433V9.82C56.2433 10.7482 56.9927 11.5009 57.9172 11.5009C58.8416 11.5009 59.5911 10.7482 59.5911 9.82V4.46862C59.5911 3.5404 58.8416 2.78768 57.9172 2.78768C56.9927 2.78768 56.2433 3.5404 56.2433 4.46862V5.46329H52.9793V1.68094C52.9793 0.752724 52.2299 0 51.3054 0H4.43593C3.51148 0 2.76208 0.752724 2.76208 1.68094V12.6075C2.76208 13.5357 3.51148 14.2885 4.43593 14.2885H9.03925V47.5725H6.81421C3.057 47.5725 0 50.6421 0 54.4156V68.3191C0 69.2473 0.749398 70 1.67385 70H14.6467C15.5712 70 16.3206 69.2473 16.3206 68.3191V63.2761H60.6794V68.3191C60.6794 69.2473 61.4288 70 62.3533 70H75.3261C76.2506 70 77 69.2473 77 68.3191V54.4156C77.0002 51.4583 75.1224 48.9338 72.5015 47.9798ZM55.7624 20.701C55.7624 20.2239 56.1489 19.8359 56.6238 19.8359H68.2924C68.7673 19.8359 69.1538 20.224 69.1538 20.701V47.5725H55.7624V20.701ZM6.10978 10.9266V3.36204H49.6316V10.9266H6.10978ZM43.3544 14.3306V47.5725H40.0065V14.3306H43.3544ZM36.6588 14.2886V47.5726H19.0827V14.2886H36.6588ZM15.7348 14.3306V47.5725H12.3869V14.3306H15.7348ZM64.0273 66.6378V61.5948C64.0273 60.6666 63.2779 59.9139 62.3534 59.9139H14.6467C13.7223 59.9139 12.9729 60.6666 12.9729 61.5948V66.6378H3.34785V54.4156C3.34785 52.4961 4.90289 50.9345 6.81421 50.9345H70.1858C72.0971 50.9345 73.6522 52.4961 73.6522 54.4156V66.6378H64.0273Z" fill="#FCFBFB" />
            </svg>
        </>
    )
}