import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { EyeSvg } from "../../SVG/EyeSvg"
import { ButtonZakaz } from "../../UI/ButtonZakaz/ButtonZakaz"
import { ButtonLike } from "../../UI/ButtonLike/ButtonLike"
import { likeOrDislike } from "../../../states/slices/likeSlice"

import fstyles from "./ProductFull.module.css"
import nstyles from "./ProductsNotFull.module.css"

export const Product = ({
    isFullProduct,
    title,
    id,
    image_urls,
    description,
    price,
    parameters
}) => {
    const liked = JSON.parse(localStorage.getItem('liked'))
    const cart = useSelector(state => state.cart)
    const [list, setList] = useState("description")
    const [isLiked, setIsLiked] = useState(false)
    const [inCart, setInCart] = useState(false)
    const dispatch = useDispatch()

    const buttonClick = (e, value) => {
        setList(value)
    }
    function onButtonLike() {
        dispatch(likeOrDislike({ id: id }))
        setIsLiked(prev => !prev)
    }
    function getCount() {
        const count = cart.find(pos => pos.id === id)?.count
        return count
    }
    function onCharClick() {
        document.getElementById('chars').scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        const isLikedStorage = liked.find(pos => pos.id === id)
        if (isLikedStorage) {
            setIsLiked(true)
        }

        const isInCart = cart.find(pos => pos.id === id)
        if (isInCart) {
            setInCart(true)
        } else {
            setInCart(false)
        }
    }, [liked, cart])

    return (
        <>
            {isFullProduct ? (
                <>
                    <h1 className={fstyles.title}>{title}</h1>
                    <div className={fstyles.content}>
                        <div className={fstyles.upper}>
                            <div className={fstyles.images}><img src={image_urls[0]} alt={title} /></div>
                            <div className={fstyles.uright}>
                                <p>Характеристики</p>
                                <ul className={fstyles.uprop}>
                                    {parameters.length < 5 ? (
                                        parameters.map((data, index) => {
                                            return (<li key={index}><span>{data[0]}</span> - <span>{data[1]}</span></li>)
                                        }
                                        )) : (
                                        <>
                                            <li><span>{parameters[0][0]}</span> - <span>{parameters[0][1]}</span></li>
                                            <li><span>{parameters[1][0]}</span> - <span>{parameters[1][1]}</span></li>
                                            <li><span>{parameters[2][0]}</span> - <span>{parameters[2][1]}</span></li>
                                            <li><span>{parameters[3][0]}</span> - <span>{parameters[3][1]}</span></li>
                                            <li><span>{parameters[4][0]}</span> - <span>{parameters[4][1]}</span></li>
                                        </>
                                    )}
                                </ul>
                                <button className={fstyles.charbutton} onClick={(e) => { onCharClick(e) }}>Все характеристики</button>
                                <div className={fstyles.right}>
                                    <ButtonZakaz id={id} isInCart={inCart} count={getCount()} />
                                    <ButtonLike isLiked={isLiked} clname={isLiked ? fstyles.liked : ''} onClick={onButtonLike} />                                </div>
                            </div>
                        </div>
                        <div className={fstyles.description} id="chars">
                            <div className={fstyles.buttons}>
                                <button onClick={(e) => { buttonClick(e, "description") }}>Описание</button>
                                <button onClick={(e) => { buttonClick(e, "params") }}>Характеристики</button>
                                <button onClick={(e) => { buttonClick(e, "payment") }}>Оплата</button>
                                <button onClick={(e) => { buttonClick(e, "shipping") }}>Доставка</button>
                            </div>
                            <div className={fstyles.options}>
                                <div className={list === "description" ? fstyles.active : fstyles.inactive} dangerouslySetInnerHTML={{ __html: description }} />
                                <div className={list === "params" ? fstyles.active : fstyles.inactive}>
                                    {parameters.map((data, index) => {
                                        return (<li key={index}><span>{data[0]}</span> - <span>{data[1]}</span></li>)
                                    })}
                                </div>
                                <div className={list === "payment" ? fstyles.active : fstyles.inactive}>Какая-то информация об оплате</div>
                                <div className={list === "shipping" ? fstyles.active : fstyles.inactive}>Доставка производится самовывозом из г. Санкт-Петербург или ТК РФ (за счёт заказчика).</div>
                            </div>
                        </div>
                    </div>

                </>
            ) : (
                <div className={nstyles.container}>
                    <div className={nstyles.img}>
                        <img src={image_urls[0]} alt="" />
                    </div>
                    <div className={nstyles.center}>
                        <div className={nstyles.center_up}>
                            <p>{title}</p>
                            <Link to={`/products/${id}`}><EyeSvg /><span>Просмотреть</span></Link>
                        </div>
                        <p className={nstyles.center_down}>ТАКЖЕ ГОТОВЫ ПОДОБРАТЬ ОБОРУДОВАНИЕ СОГЛАСНО ВАШЕГО ЗАПРОСА</p>
                    </div>
                    <div className={nstyles.right}>
                        <ButtonZakaz id={id} isInCart={inCart} count={getCount()} />
                        <ButtonLike isLiked={isLiked} clname={isLiked ? nstyles.liked : ''} onClick={onButtonLike} />
                    </div>
                </div>
            )}
        </>
    )
}