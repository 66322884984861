import { useEffect, useState } from "react"
import { apiUrl } from "../../axios"
import { Product } from "../Parts/Product/Product"


export const ProductContainer = ({ data }) => {
    const [item, setItem] = useState({})
    const [loading, setLoading] = useState(true)

    const params = new URLSearchParams()
    params.append('product_id', data)

    useEffect(() => {
        apiUrl
            .get('/catalog/product', { params })
            .then(data => {
                setItem(data.data.product)
                setLoading(false)
            })
            .catch(err => console.log(err))
    }, [localStorage.getItem('liked'), localStorage.getItem('cart')])
    return (
        <>
            {loading ? 'Загрузка...' : (
                <Product
                    isFullProduct={false}
                    id={item.id}
                    title={item.title}
                    image_urls={item.images_url}
                />
            )}
        </>
    )
}