import { ProductionSvg } from "../../SVG/Production"
import { GearsSvg } from "../../SVG/Gears"
import { LinesLeftSvg } from "../../SVG/LinesLeft/LinesLeft"
import { LinesRightSvg } from "../../SVG/LinesRight/LinesRight"

import styles from "./Feedback.module.css"
import { Modal } from "../../UI/Modal/Modal"
import { useState } from "react"

export const Feedback = () => {
    const [isActive, setInactive] = useState(false)

    return (
        <div className={styles.container}>
            <Modal type='feedback' isActive={isActive} setInactive={setInactive} />
            <div className={styles.images}>
                <div className={styles.production}>
                    <ProductionSvg />
                </div>
                <div className={styles.gears}>
                    <GearsSvg />
                </div>
                <div className={styles.lines_left}>
                    <LinesLeftSvg />
                </div>
                <div className={styles.lines_right}>
                    <LinesRightSvg />
                </div>
            </div>
            <div className={styles.content}>
                <div>Сотрудничаем с госучереждениями в соответствии с 44 и 223 ФЗ</div>
                <button onClick={() => setInactive(prev => !prev)}>Оставить заявку</button>
            </div>
        </div>
    )
}