import { useDispatch } from "react-redux";
import { addToCart, decrementCart } from "../../../states/slices/cartSlice";
import { CartSvg } from "../../SVG/CartSvg"

import styles from './ButtonZakaz.module.css'

export const ButtonZakaz = ({ id, isInCart, count }) => {
    const dispatch = useDispatch()

    function onButtonZakaz(action) {
        switch (action) {
            case 'DEC':
                dispatch(decrementCart({ id: id }));
                break;
            default:
                dispatch(addToCart({ id: id, count: 1 }));
                break;
        }
    }
    return (
        <>
            {isInCart ? (<>
                <div className={styles.btn} style={{ display: "flex", alignItems: "center" }}>
                    <button className={styles.btn_d} onClick={() => onButtonZakaz('DEC')}>-</button>
                    <span className={styles.count} style={({ fontSize: "inherit" })}>{count}</span>
                    <button className={styles.btn_i} onClick={() => onButtonZakaz()}>+</button>
                </div>
            </>) : (<>
                <button onClick={() => onButtonZakaz()}>
                    <CartSvg /> Заказать
                </button>
            </>)}
        </>
    )
}