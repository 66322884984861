export default class ArrayLocalStorageState {
    constructor(typeOfVal) {
        this.typeOfVal = typeOfVal
    }

    createArrayState() {
        const localState = localStorage.getItem(this.typeOfVal)
        if (!localState) {
            localStorage.setItem(this.typeOfVal, JSON.stringify([]))
        }
    }

    getArrayState() {
        return localStorage.getItem(JSON.parse(this.typeOfVal))
    }

    resetArrayState() {
        localStorage.setItem(this.typeOfVal, JSON.stringify([]))
    }

    getArraysState(argums) {
        let returnArray = [];
        argums.forEach(el => {
            returnArray.push(JSON.parse(localStorage.getItem(el)))
        });

        return returnArray
    }
}