import { useForm } from "react-hook-form"
import { useState } from "react";
import { Fetcher } from "../../../classes/utils/Fetchers";

export const Form = ({ clname, URI, plholders, text, additionalData, funcClose }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    const onSubmit = data => {
        const userData = { ...data, text, ...additionalData }

        setLoading(true)

        Fetcher.prototype.postForm(URI, userData)
            .then(res => {
                console.log(res)
                setLoading(false)
                funcClose(prev => !prev)
            })
            .catch(err => {
                console.warn(err)
                setLoading(false)
                setError(err)
            })
    }

    return (
        <>
            <form className={clname} onSubmit={handleSubmit(onSubmit)}>
                <input type="tel" placeholder={'Ваш номер телефона'} {...register("phone", { required: true })} />
                {errors.phone && <span>Оставьте ваш контактный номер</span>}
                <input type="text" placeholder={'Ваше имя'} {...register("name", { required: true })} />
                {errors.name && <span>Укажите, как к вам обращаться</span>}
                <input type="email" placeholder={'Ваш электронный адрес'} {...register("email", { required: true })} />
                {errors.email && <span>Укажите ваш электронный адрес</span>}
                <button disabled={loading}>Отпарвить</button>
                {loading === true ? (<span>Отправка...</span>) : (<></>)}
                {loading === false ? (<span style={{ color: "green" }}>Успешно отправлено!</span>) : (<></>)}
                {error ? (<span>Ошибка отправки</span>) : (<></>)}
            </form>
        </>
    )
}