import { Link, useParams } from "react-router-dom";
import { Product } from "../Product/Product";

import styles from "./Catalogue.module.css"

export const Catalogue = ({ isMain, loading, data, isProducts }) => {
    const params = useParams();
    const cats = data;

    const isSubCats = params.category_id ? true : false;
    return (
        <>
            {isMain ? (<></>)
                : (<div className={styles.links}>
                    <Link to='/'>главная</Link> - <Link to='/catalogue'>каталог</Link>{isSubCats ?
                        (loading ?
                            (<></>)
                            : (<><Link to={`/catalogue/${cats.category.id}`}>- {cats.category.title.toLowerCase()}</Link></>))
                        : (<></>)}
                </div>)}
            <h1 className={styles.header}>{loading ? "продукции" : (`Каталог ${isSubCats ? cats.category.title : "продукции"}`)}</h1>

            {isProducts ? (
                <div className={styles.products}>
                    {loading ? (
                        <div>
                            Загрузка...
                        </div>
                    ) : (cats.products.map((data, index) => {
                        return (
                            <Product
                                key={index}
                                id={data.id}
                                title={data.title}
                                image_urls={data.images_url}
                                price={data.price}
                                description={data.description}
                                parameters={data.parameters}
                            />
                        )
                    }))}
                </div>
            ) : (
                <div className={styles.cats}>
                    {
                        loading ? (
                            <div>
                                Загрузка...
                            </div>
                        ) : (isSubCats ? cats.subcategories : cats.categories).map((data, index) => {
                            return (
                                <div key={index} className={styles.cat}>
                                    <Link to={'/catalogue/' + (isSubCats ? (params.category_id + "/" + data.id) : (data.id))}>
                                        <img src={data.image_url} alt="Изображение" />
                                        <p>{data.title}</p>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            )}
        </>
    )
}