import { useState } from "react"
import { useSelector } from "react-redux"
import { Container } from "../../components/UI/Container/Container"
import { ProductContainer } from "../../components/Containers/ProductContainer"
import { Link } from "react-router-dom"
import { Modal } from "../../components/UI/Modal/Modal"

import styles from './Cart.module.css'

export const Cart = () => {
    const cart = useSelector(state => state.cart)
    const [formActive, setFormActive] = useState(false)

    function onSubmit() {
        alert('meow')
    }
    return (
        <Container>
            <h1 className={styles.h}>Корзина</h1>
            <ul className={styles.ul}>
                {cart.length ? (<>
                    {cart.map((item, id) => {
                        return (
                            <li key={id}>
                                <ProductContainer data={item.id} />
                            </li>
                        )
                    })}
                </>) : (<>
                    <p className={styles.backlink}>В корзине пусто.&nbsp;<Link to='/catalogue'>Перейдите в каталог</Link></p>
                </>)}
            </ul>
            {cart.length ? (
                <button className={styles.button} onClick={() => setFormActive(prev => !prev)}>Оформить заказ</button>
            ) : (<></>)}
            <div className={formActive ? styles.form : styles.inactive}>

            </div>
            <Modal type="zakaz" isActive={formActive} setInactive={setFormActive} addData={cart} />
        </Container>
    )
}