import { useSelector } from "react-redux"
import { Container } from "../../components/UI/Container/Container"
// import { ProductContainer } from "../../classes/components/ProductContainer"
import { ProductContainer } from "../../components/Containers/ProductContainer"
import { Link } from "react-router-dom"

import styles from './Liked.module.css'

export const Liked = () => {
    const liked = useSelector(state => state.liked)
    return (
        <Container>
            <h1 className={styles.h}>Отложенные товары</h1>
            <ul className={styles.ul}>
                {liked.length ? (<>
                    {liked.map((item, id) => {
                        return (
                            <li key={id}>
                                <ProductContainer data={item.id} />
                            </li>
                        )
                    })}
                </>) : (<>
                    <p className={styles.backlink}>Вы ничего ещё не откладывали.&nbsp;<Link to='/catalogue'>Перейдите в каталог</Link></p>
                </>)}
            </ul>
        </Container>
    )
}