import { configureStore } from "@reduxjs/toolkit";
import cartSlice from './slices/cartSlice'
import likeSlice from "./slices/likeSlice";


const store = configureStore({
    reducer: {
        cart: cartSlice,
        liked: likeSlice
    }
})

export default store