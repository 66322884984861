import { createSlice } from "@reduxjs/toolkit";
import ArrayLocalStorageState from "../../classes/utils/ArrayLocalStorageState";

const arrayState = new ArrayLocalStorageState('liked')
arrayState.createArrayState()

const initialState = localStorage.getItem('liked') ? JSON.parse(localStorage.getItem('liked')) : arrayState.getArrayState();

const likeSlice = createSlice({
    name: 'liked',
    initialState: initialState,
    reducers: {
        likeOrDislike(state, action) {
            const { id } = action.payload;
            const existingPos = state.find(pos => pos.id === id)
            if (existingPos) {
                state.splice(state.indexOf(existingPos), 1)
                localStorage.setItem('liked', JSON.stringify(state))
            } else {
                state.push(action.payload)
                localStorage.setItem('liked', JSON.stringify(state))
            }
        }
    },
})

export const { likeOrDislike } = likeSlice.actions
export default likeSlice.reducer