import { HeaderLower } from "../HeaderLower/HeaderLower"
import { HeaderUpper } from "../HeaderUpper/HeaderUpper"
import { HeaderStill } from "../HeaderStill/HeaderStill"

import styles from "./Header.module.css"

export const Header = () => {
    return (
        <>
            <div className={styles.header}>
                <HeaderUpper />
                <HeaderLower />
            </div>
            <HeaderStill />
        </>
    )
}