import React, { Component } from 'react';

export class Spacer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: props.h
        };
    }

    componentDidMount() {
        this.heightCalculator();
        window.addEventListener('resize', this.heightCalculator);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.heightCalculator);
    }

    heightCalculator = () => {
        if (!this.props) {
            return;
        }

        const w = window.innerWidth;
        let newHeight;

        if (w > 1600) {
            newHeight = this.props.h + 'px';
        } else if (w <= 1600 && w > 992) {
            newHeight = ((this.props.h * 100) / 1440) + 'rem';
        } else if (w > 568 && w <= 992) {
            newHeight = ((this.props.h * 100) / 768) + 'rem';
        } else {
            newHeight = ((this.props.h * 100) / 360) + 'rem';
        }

        this.setState({ height: newHeight });
    }

    render() {
        return <div style={{ height: this.state.height }} />;
    }
}